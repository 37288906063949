import { trans } from "@resources/localization"
import { Card, Checkbox, Col, Form, Input, Row, Select, Space, Switch, Typography } from "antd"
import { chunk, filter, isEmpty } from "lodash"
import "@resources/less/page/product.less"
import React from "react"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"

type Props = {
    formRef: any
    listCurrentSku: any
    listActive: any
    setListActive: any
}

export const Properties: React.FC<Props> = ({ formRef, listCurrentSku, listActive, setListActive }) => {
    const disableEdit = !SecurityService.can(PRODUCT_SCOPE.PRODUCT_UPDATE)
    return (
        <Card className="space-layout">
            <Row
                gutter={30}
                className="mb-2">
                <Col span={12}>
                    <Typography.Text>{trans("product.property")}</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text className="text-left w-full">{trans("product.detail_property")}</Typography.Text>
                </Col>
            </Row>
            <Form.List name="properties">
                {(fields) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row
                                gutter={30}
                                key={key}>
                                <Col span={12}>
                                <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre?.properties[name]?.value !== next?.properties[name]?.value}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                name={[name, "attribute"]}
                                                rules={
                                                    !isEmpty(getFieldValue(["properties", name, "value"]))
                                                        ? [
                                                              {
                                                                  required: true,
                                                                  message: trans("message.required"),
                                                              },
                                                          ]
                                                        : []
                                                }>
                                                <Input
                                                    maxLength={16}
                                                    placeholder={trans("product.attribute_name_pl")}
                                                    onChange={(e: any) => {
                                                        const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                                                        const listProperties: any = formRef.getFieldValue("properties")?.slice()
                                                        listProperties[name] = {
                                                            ...listProperties[name],
                                                            attribute: text,
                                                        }
                                                        formRef.setFieldsValue({
                                                            properties: listProperties,
                                                        })
                                                    }}
                                                    disabled={disableEdit || getFieldValue(["properties", name, "disable"]) }
                                                    onBlur={(e) => {
                                                        const listProperties: any = formRef.getFieldValue("properties")?.slice()
                                                        listProperties[name] = {
                                                            ...listProperties[name],
                                                            attribute: e.target.value.trim(),
                                                        }
                                                        formRef.setFieldsValue({
                                                            properties: listProperties,
                                                        })
                                                    }}
                                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre?.properties[name]?.attribute !== next?.properties[name]?.attribute}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                rules={
                                                    getFieldValue(["properties", name, "attribute"])
                                                        ? [
                                                              {
                                                                  required: true,
                                                                  message: trans("message.required"),
                                                              },
                                                          ]
                                                        : []
                                                }
                                                name={[name, "value"]}>
                                                <Select
                                                    mode="tags"
                                                    placeholder={trans("product.attribute_name_pl")}
                                                    disabled={disableEdit || !getFieldValue(["properties", name, "attribute"])}>
                                                    {formRef.getFieldValue(["properties", name, "value"])?.map((item: string, index: any) => (
                                                        <Select.Option
                                                            key={index}
                                                            value={item}
                                                            disabled={
                                                                !isEmpty(filter(formRef.getFieldValue(["properties", name, "oldAttribute"]), { value: item }))
                                                            }>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Form.List>
            {!isEmpty(listCurrentSku) && (
                <Card title={`${trans("products.select_all")} ${listCurrentSku?.length} ${trans("products.properties")}`}>
                    <Form.Item name="selected">
                        <Checkbox.Group className="w-full">
                            <Row
                                gutter={15}
                                className="w-full">
                                {!isEmpty(listCurrentSku) &&
                                    chunk(listCurrentSku, 20)?.map((item: any, index: number) => (
                                        <Col
                                            className="custom-check-box"
                                            span={12}
                                            key={index + 1}>
                                            {item?.map((iChild: { label?: string, value: number, disable?: boolean, checked?: boolean, sku?: string }) => (
                                                <Space className="custom--edit" key={iChild?.value}>
                                                    <Checkbox
                                                        className="w-72 break-all"
                                                        disabled={disableEdit || (iChild?.disable && iChild.checked)}
                                                        value={iChild?.value}>
                                                        {`${iChild?.label}${iChild?.sku !== 'SKU' ? `(${iChild?.sku})` : ''}`}
                                                    </Checkbox>
                                                    <Space>
                                                        <Switch
                                                            checked={listActive.includes(iChild.value)}
                                                            disabled={disableEdit}
                                                            onChange={(e) => {
                                                                const oldList = listActive.slice()
                                                                if (e) {
                                                                    oldList.push(iChild.value)
                                                                    setListActive(oldList)
                                                                } else {
                                                                    const newList = oldList.filter((item: number) => item !== iChild.value)
                                                                    setListActive(newList)
                                                                }
                                                            }}
                                                        />
                                                        <Typography.Text className="mt-9 ml-2">{trans("user_list.active")}</Typography.Text>
                                                    </Space>
                                                </Space>
                                            ))}
                                        </Col>
                                    ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Card>
            )}
        </Card>
    )
}
